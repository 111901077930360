export type LogFn = (...args: any) => void

const SdkContext = '[WebEmarsysSDK]'
const SwContext = '[WebEmarsysSW]'

interface IConsoleLogging {
  trace: LogFn,
  debug: LogFn,
  info: LogFn,
  warn: LogFn,
  error: LogFn,
  log: LogFn,
  [key: string]: LogFn // just for being able to loop over the entries
}

// tslint:disable-next-line:no-empty
const NoopLogFn: LogFn = () => {}

const loggingFunction = (context: string, level: string) => {
  return (...data: any[]) => {
    (console as any)[level](context, ...data)
  }
}

/**
 * Enables the Logger by setting all log function references to the respective
 * functions of the global console.
 */
function enableLogger (enabled: boolean, context: string = SdkContext) {
  const methods = [ 'trace', 'debug', 'info', 'warn', 'error', 'log' ]
  if (enabled) {
    for (const method of methods) {
      Logger[method] = loggingFunction(context, method)
    }
  } else {
    for (const method of methods) {
      Logger[method] = NoopLogFn
    }
  }
}

/**
 * The logger which provides logging functions. By default the functions
 * are disabled which means they point to the NoopLogFn.
 */
const Logger: IConsoleLogging = {
  trace: NoopLogFn,
  debug: NoopLogFn,
  info: NoopLogFn,
  warn: NoopLogFn,
  error: NoopLogFn,
  log: NoopLogFn
}

const exportedParts = {
  NoopLogFn,
  enableLogger,
  SdkContext,
  SwContext,
  Logger
}

export default exportedParts
