import logging from './logging'
import { MEV3ApiRequest } from './me-v3-api-request'
import { MEWebPushDb } from './me-web-push-db'
import { StorageWithLog } from './storage-with-log'

const { Logger } = logging

type MEEventType = 'internal' | 'custom' | 'other'
export type MEEventAttributes = { [key: string]: string }

export type MEEvent = {
  type: MEEventType,
  name: string,
  timestamp: string,
  attributes?: MEEventAttributes
}

/**
 * The data we send
 */
export type MEEventsRequestData = {
  dnd?: boolean,
  events: MEEvent[],
  viewedMessages: any[],
  clicks: any[]
}

export type ClientDetails = {
  platform:	string,
  applicationVersion?: string,
  deviceModel?:	string,
  osVersion?:	string,
  sdkVersion?: string,
  language?: string,
  timezone?: string
}

export type PostEventsResult = { success: boolean, statusCode?: number }

const PostEventsOKStates = [200, 204]

export class MEDeviceEventService {

  private storage: MEWebPushDb
  private readonly storageWithLog: StorageWithLog
  private readonly baseUrl: string
  private desRequest: MEV3ApiRequest

  constructor (
    baseUrl: string,
    desRequest: MEV3ApiRequest,
    storage: MEWebPushDb
  ) {
    this.storage = storage
    this.storageWithLog = new StorageWithLog(storage)
    this.baseUrl = `${baseUrl}/apps`
    this.desRequest = desRequest
  }

  /**
   * Post the passed data which includes 1 or more events to the device event service
   * @param eventsData The events which shall be forwarded to the DES.
   * @returns A promise which resolves to a PostEventsResult which indicates the success
   *          of the operation and contains the returned status code.
   */
  async postEvents (eventsData: MEEventsRequestData): Promise<PostEventsResult> {
    const clientId = await this.storageWithLog.getClientId()
    const contactToken = await this.storageWithLog.getContactToken()
    const clientState = await this.storageWithLog.getClientState()
    if (!clientId || !contactToken || !clientState) {
      return { success: false }
    }
    const apiEndpoint = await this.apiEndpoint('client/events')
    const response = await this.desRequest.post(apiEndpoint, eventsData, { clientId, clientState, contactToken })
    if (PostEventsOKStates.includes(response.status)) {
      return { success: true, statusCode: response.status }
    } else {
      const body = await response.json()
      Logger.warn('Error posting events to device event service', response.status, JSON.stringify(body))
      return { success: false, statusCode: response.status }
    }
  }

  private async apiEndpoint (path: string): Promise<string> {
    const appCode = await this.storage.getAppCode()
    return `${this.baseUrl}/${appCode}/${path}`
  }

  static create (
    baseUrl: string,
    desRequest: MEV3ApiRequest,
    storage: MEWebPushDb
  ) {
    return new MEDeviceEventService(baseUrl, desRequest, storage)
  }
}
