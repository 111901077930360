import { urlB64ToString } from './utils'
import logging from './logging'

export type DecodeOptions = {
  header?: boolean
}

export type DecodedToken = { [key: string]: any }

export function decode (token: string, options?: DecodeOptions): DecodedToken | null {
  const opts = options || {}
  const pos = opts.header === true ? 0 : 1
  try {
    return JSON.parse(urlB64ToString(token.split('.')[pos]))
  } catch (err) {
    logging.Logger.error('Error decoding token', err)
    return null
  }
}
