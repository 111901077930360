import * as CONSTANTS from './constants'
import { IndexDb } from './index-db'

/**
 * The ME web push persisted information
 */
export class MEWebPushDb {
  private readonly indexDb: IndexDb
  private appCode: string | undefined

  constructor (indexDb: IndexDb) {
    this.indexDb = indexDb
  }

  getDefaultNotificationTitle (defaultTitle: string): Promise<string> {
    return (this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyDefaultNotificationTitle, defaultTitle) as Promise<string>)
  }

  getDefaultNotificationIcon (defaultImage: string | undefined): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyDefaultNotificationIcon, defaultImage)
  }

  async getClientIdForAppCode (appCode: string | undefined): Promise<string | undefined> {
    if (appCode !== undefined) {
      const browserIds: ClientIdList = await this.getBrowserIdsFromDb()
      return browserIds[appCode.toUpperCase()]
    }
  }

  getClientIds (): Promise<ClientIdList | undefined> {
    return this.getBrowserIdsFromDb()
  }

  private async getBrowserIdsFromDb (): Promise<ClientIdList> {
    let browserIdsString: string | undefined = await this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyBrowserIds)
    let browserIds: { [appCode: string]: string } = {}
    if (browserIdsString !== undefined) {
      // tslint:disable-next-line:no-empty
      try { browserIds = JSON.parse(browserIdsString) } catch (err) { }
    }
    browserIds = await this.addLegacyBrowserId(browserIds)
    return browserIds
  }

  private async addLegacyBrowserId (browserIds: ClientIdList): Promise<ClientIdList> {
    const legacyBrowserId = await this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyBrowserId)
    if (legacyBrowserId !== undefined) {
      const parts = legacyBrowserId.split('_')
      browserIds[parts[0].toUpperCase()] = legacyBrowserId
      await this.indexDb.setDBValue(CONSTANTS.dbKeyBrowserIds, JSON.stringify(browserIds))
      await this.indexDb.setDBValue(CONSTANTS.dbKeyBrowserId, undefined)
    }
    return browserIds
  }

  async getInitParams (): Promise<IInitParams | undefined> {
    const params = await this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyInitParams)
    return params ? JSON.parse(params) : undefined
  }

  getMeClientServiceApiBaseUrl (): Promise<string> {
    return this.indexDb.getDBValue(CONSTANTS.dbKeyMeClientServiceApiBaseUrl)
  }

  getMeDeviceEventServiceApiBaseUrl (): Promise<string> {
    return this.indexDb.getDBValue(CONSTANTS.dbKeyMeDeviceEventServiceApiBaseUrl)
  }

  async getClientId (): Promise<string | undefined> {
    const appCode = await this.getAppCode()
    return this.getClientIdForAppCode(appCode)
  }

  getClientState (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyXClientState)
  }

  getRefreshToken (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyRefreshToken)
  }

  getContactToken (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyContactToken)
  }

  async getAppCode (): Promise<string | undefined> {
    if (!this.appCode) {
      this.appCode = await this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyApplicationCode)
    }
    return this.appCode
  }

  async getWebsitePushId (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyWebsitePushId)
  }

  async getLastPermissionStatus (): Promise<NotificationPermission | undefined> {
    return this.indexDb
      .getDBValueOrDefault(CONSTANTS.dbKeyLastPermissionStatus)
      .then(v => v as NotificationPermission)
  }

  async getApplicationServerPublicKey (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyApplicationServerPublicKey)
  }

  async getServiceWorkerUrl (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyServiceWorkerUrl)
  }

  async getServiceWorkerScope (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyServiceWorkerScope)
  }

  async getSdkVersion (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeySdkVersion)
  }

  async getServiceWorkerVersion (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyServiceWorkerVersion)
  }

  async getPushToken (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyPushToken)
  }

  async getContactFieldId (): Promise<number | undefined> {
    const fieldId = await this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyContactFieldId)
    return fieldId ? Number(fieldId) : undefined
  }

  async getContactFieldValue (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyContactFieldValue)
  }

  async getPushPackageServiceUrl (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyPushPackageServiceUrl)
  }

  async getPlatform (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyPlatform)
  }

  async getApplicationVersion (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyApplicationVersion)
  }

  async getDeviceModel (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyDeviceModel)
  }

  async getOsVersion (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyOsVersion)
  }

  async getLanguage (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyLanguage)
  }

  async getTimezone (): Promise<string | undefined> {
    return this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyTimezone)
  }

  async getLoggingEnabled (): Promise<boolean> {
    const flag = await this.indexDb.getDBValueOrDefault(CONSTANTS.dbKeyLoggingEnabled)
    return flag?.toLowerCase() === 'true'
  }

  async setTimezone (value: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyTimezone, value)
  }

  async setLanguage (value: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyLanguage, value)
  }

  async setOsVersion (value: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyOsVersion, value)
  }

  async setDeviceModel (value: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyDeviceModel, value)
  }

  async setApplicationVersion (value: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyApplicationVersion, value)
  }

  async setPlatform (value: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyPlatform, value)
  }

  async setPushPackageServiceUrl (url: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyPushPackageServiceUrl, url)
  }

  async setContactFieldValue (fieldValue: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyContactFieldValue, fieldValue)
  }

  async setContactFieldId (fieldId: number | undefined): Promise<void> {
    await this.indexDb.setDBValue(
      CONSTANTS.dbKeyContactFieldId,
      typeof fieldId === 'number' ? fieldId.toString() : undefined
    )
  }

  async setMeClientServiceApiBaseUrl (url: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyMeClientServiceApiBaseUrl, url)
  }

  async setMeDeviceEventServiceApiBaseUrl (url: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyMeDeviceEventServiceApiBaseUrl, url)
  }

  async setPushToken (pushToken: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyPushToken, pushToken)
  }

  async setServiceWorkerVersion (version: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyServiceWorkerVersion, version)
  }

  async setSdkVersion (sdkVersion: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeySdkVersion, sdkVersion)
  }

  async setLastPermissionStatus (status: NotificationPermission | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyLastPermissionStatus, status)
  }

  async setAppCode (appCode: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyApplicationCode, appCode)
    this.appCode = appCode
  }

  async setClientState (clientState: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyXClientState, clientState)
  }

  async setContactToken (contactToken: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyContactToken, contactToken)
  }

  async setRefreshToken (refreshToken: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyRefreshToken, refreshToken)
  }

  private async setClientId (clientId: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyBrowserId, clientId)
  }

  async setClientIdForAppCode (clientId: string | undefined, appCode: string): Promise<void> {
    const browserIds = await this.getBrowserIdsFromDb()
    if (clientId !== undefined) {
      browserIds[appCode.toUpperCase()] = clientId
    } else {
      delete browserIds[appCode.toUpperCase()]
    }
    await this.indexDb.setDBValue(CONSTANTS.dbKeyBrowserIds, JSON.stringify(browserIds))
  }

  async setClientIds (clientIds: ClientIdList | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyBrowserIds, JSON.stringify(clientIds))
  }

  async setDefaultNotificationIcon (icon: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyDefaultNotificationIcon, icon)
  }

  async setDefaultNotificationTitle (icon: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyDefaultNotificationTitle, icon)
  }

  async setServiceWorkerUrl (url: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyServiceWorkerUrl, url)
  }

  async setServiceWorkerScope (scope: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyServiceWorkerScope, scope)
  }

  async setApplicationServerPublicKey (key: string | undefined): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyApplicationServerPublicKey, key)
  }

  async setInitParams (params: IInitParams | undefined) {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyInitParams, JSON.stringify(params))
  }

  async setWebsitePushId (id: string | undefined) {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyWebsitePushId, id)
  }

  async setLoggingEnabled (flag: boolean): Promise<void> {
    await this.indexDb.setDBValue(CONSTANTS.dbKeyLoggingEnabled, `${flag}`)
  }

  async setLastUsedAt (): Promise<void> {
    const d = new Date().toISOString()
    await this.indexDb.setDBValue(CONSTANTS.dbKeyLastUsedAt, d)
  }

  async deleteLastUsedAt (): Promise<void> {
    await this.indexDb.deleteDBKey(CONSTANTS.dbKeyLastUsedAt)
  }

  async clearAll (): Promise<void> {
    await Promise.all([
      this.setAppCode(undefined),
      this.setClientState(undefined),
      this.setContactToken(undefined),
      this.setRefreshToken(undefined),
      this.setDefaultNotificationIcon(undefined),
      this.setDefaultNotificationTitle(undefined),
      this.setServiceWorkerUrl(undefined),
      this.setServiceWorkerScope(undefined),
      this.setApplicationServerPublicKey(undefined),
      this.setSdkVersion(undefined),
      this.setServiceWorkerVersion(undefined),
      this.setInitParams(undefined),
      this.setWebsitePushId(undefined),
      this.setPushPackageServiceUrl(undefined),
      this.setLastPermissionStatus(undefined),
      this.setPushToken(undefined),
      this.setContactFieldId(undefined),
      this.setContactFieldValue(undefined),
      this.setPlatform(undefined),
      this.setApplicationVersion(undefined),
      this.setDeviceModel(undefined),
      this.setTimezone(undefined),
      this.setLanguage(undefined),
      this.setOsVersion(undefined),
      this.setMeClientServiceApiBaseUrl(undefined),
      this.setMeDeviceEventServiceApiBaseUrl(undefined),
      this.deleteLastUsedAt()
    ])
    this.appCode = undefined // clear the cache
  }

  static create (indexDb: IndexDb): MEWebPushDb {
    return new MEWebPushDb(indexDb)
  }
}
