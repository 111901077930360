import { NavigatorInfo } from './navigator-info'

export class PushNotificationSupportInfo {
  private readonly navigatorInfo: NavigatorInfo
  private readonly window: Window

  constructor (navigatorInfo: NavigatorInfo, win: Window) {
    this.navigatorInfo = navigatorInfo
    this.window = win
  }

  pushNotificationsSupported (): boolean {
    return this.canUseSafariPush() || this.canUseServiceWorkers()
  }

  canUseServiceWorkers (): boolean {
    return (
      this.navigatorInfo.hasServiceWorker()
      && ('PushManager' in this.window)
    )
  }

  canUseSafariPush (): boolean {
    return 'safari' in this.window && 'pushNotification' in this.window.safari
  }

  canUsePromises (): boolean {
    return 'Promise' in this.window
  }

  static create (navigatorInfo: NavigatorInfo, win: Window): PushNotificationSupportInfo {
    return new PushNotificationSupportInfo(navigatorInfo, win)
  }
}
